//
// Client
//

.client {
    img {
        width: 100%;
        max-width: 6rem;
        height: auto;
        margin-left: auto;
        margin-right: auto;
    }
}
