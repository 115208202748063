//
// Fancybox
// Advanced layouts
//

.fancybox-button--arrow_left,
.fancybox-button--arrow_right {
    transform: scale(1.5);
}

.fancybox-button--close {
    width: 110px;
    display: inline-flex;

    svg {
        width: auto;
        margin-right: 5px;
    }
}
// Product quick view

.quick-view-container {
    background: rgba(10, 10, 10, .85);
}

.quick-view-content {
    bottom: 0;
    height: calc(100% - 40px);
    left: 0;
    margin: auto;
    max-height: 650px;
    max-width: 980px;
    position: absolute;
    right: 0;
    top: 0;
    width: calc(100% - 40px);
}

.quick-view-carousel {
    background: $card-bg;
    border: 1px solid $card-border-color;
    border-radius: $card-border-radius;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 57%;
    z-index: 10;

    @include box-shadow($box-shadow);

    .fancybox-stage {
        bottom: 30px;
    }
}

.quick-view-aside {
    background: $card-bg;
    border-top-right-radius: $card-border-radius;
    border-bottom-right-radius: $card-border-radius;
    bottom: 2rem;
    color: $card-color;
    left: auto;
    padding: 3rem 0 2rem 0;
    position: absolute;
    right: 0;
    top: 2rem;
    width: 43%;

    > div {
        height: 100%;
        overflow: auto;
        padding: 0 2rem;
    }
}

.quick-view-close {
    background: #f0f0f0;
    border: 0;
    border-top-right-radius: $card-border-radius;
    border-bottom-left-radius: $card-border-radius;
    color: #222;
    cursor: pointer;
    font-family: Arial;
    font-size: 14px;
    height: 44px;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 0;
    text-indent: -99999px;
    top: 2rem;
    transition: all .2s;
    width: 2.75rem;

    &:hover {
        background: #e4e4e4;
    }

    &:before,
    &:after {
        background-color: #222;
        content: '';
        height: 18px;
        left: 22px;
        position: absolute;
        top: 12px;
        width: 1px;
    }

    &:before {
        -webkit-transform: rotate(-45deg);
           -moz-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
             -o-transform: rotate(-45deg);
                transform: rotate(-45deg);
    }

    &:after {
        -webkit-transform: rotate(45deg);
           -moz-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
             -o-transform: rotate(45deg);
                transform: rotate(45deg);
    }
}

.quick-view-bullets {
    bottom: 0;
    left: 0;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    text-align: center;
    width: 100%;
    z-index: 99999;

    li {
        display: inline-block;
        vertical-align: top;

        a {
            display: block;
            height: 30px;
            position: relative;
            width: 20px;

            span {
                background: $pagination-bullet-bg;
                border-radius: 99px;
                height: 10px;
                left: 50%;
                overflow: hidden;
                position: absolute;
                text-indent: -99999px;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 10px;
            }
        }

        &.active a span {
            background: $pagination-bullet-active-bg;
        }
    }
}


/*

Advanced example - Customized layout

*/

@media all and (min-width: 600px) {

    .fancybox-custom-layout {
        width: 90%;
        height: 90%;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%) translateZ(0) !important;
        @include box-shadow($box-shadow-xl);
    }

    /* Change color for backdrop */
    .fancybox-custom-layout .fancybox-bg {
        background: #fcfaf9;
    }

    .fancybox-custom-layout.fancybox-is-open .fancybox-bg {
        opacity: 1;
    }

    /* Move caption area to the right side */
    .fancybox-custom-layout .fancybox-caption {
        display: none;
        background: #f1ecec;
        bottom: 0;
        color: #6c6f73;
        left: auto;
        padding: 30px 20px;
        right: 44px;
        top: 0;
        width: 256px;
    }

    .fancybox-custom-layout .fancybox-caption h3 {
        color: #444;
        font-size: 21px;
        line-height: 1.3;
        margin-bottom: 24px;
    }

    .fancybox-custom-layout .fancybox-caption a {
        color: #444;
    }

    /* Remove gradient from caption*/
    .fancybox-custom-layout .fancybox-caption::before {
        display: none;
    }

    /* Adjust content area position */
    .fancybox-custom-layout .fancybox-stage {
        right: 300px;
    }

    /* Align buttons at the right side  */
    .fancybox-custom-layout .fancybox-toolbar {
        background: #3b3b45;
        bottom: 0;
        left: auto;
        right: 0;
        top: 0;
        width: 44px;
    }

    /* Remove background from all buttons */
    .fancybox-custom-layout .fancybox-button {
        background: transparent;
    }

    /* Navigation arrows */
    .fancybox-custom-layout .fancybox-navigation .fancybox-button div {
        padding: 6px;
        background: #fcfaf9;
        border-radius: 50%;
        transition: opacity .2s;
        box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
        opacity: .7;
    }

    .fancybox-custom-layout .fancybox-navigation .fancybox-button:not([disabled]):hover div {
        opacity: 1;
    }

    .fancybox-custom-layout .fancybox-navigation .fancybox-button[disabled] {
        color: #999;
    }

    .fancybox-custom-layout .fancybox-navigation .fancybox-button:not([disabled]) {
        color: #333;
    }

    /* Reposition right arrow */
    .fancybox-custom-layout .fancybox-button--arrow_right {
        right: 308px;
    }
}
