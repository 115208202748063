//
// Section rotate
//


.section-half-rounded {
    padding-top: 7rem;
    padding-bottom: 7rem;
    background: transparent;
    position: relative;

    // half bg
    .section-inner {
        position: absolute;
        top: 0;
        height: 100%;
        z-index: -2;
    }

    @include media-breakpoint-down(md) {
        .section-inner {
            width: 100%;
        }
    }
}
