//
// badge dot
//


.badge-dot {
    padding-left: 0;
    padding-right: 0;
    background: transparent;
    color: $gray-700;
    font-size: $font-size-sm;

    i {
        display: inline-block;
        vertical-align: middle;
        width: .375rem;
        height: .375rem;
        border-radius: 50%;
        margin-right: .375rem;
    }

    &.badge-md {
        i {
            width: .5rem;
            height: .5rem;
        }
    }

    &.badge-lg {
        i {
            width: .625rem;
            height: .625rem;
        }
    }
}
