//
// Nav dots
//

.nav-dots {
    top: 50%;
    right: 2rem;
    position: fixed;
    z-index: 200;
    transform: translateY(-50%);

    .nav-link {
        display: block;
        margin: 5px;
        position: relative;

        &:before {
            content: '';
            border-radius: 50%;
            position: absolute;
            z-index: 1;
            height: 6px;
            width: 6px;
            border: 0;
            background: $white;
            left: 50%;
            top: 50%;
            margin: -2px 0 0 -2px;
            transform: translate(-50%, -50%);
            transition: all .1s ease-in-out;
        }

        &:hover,
        &.active {
            &:before {
                height: 12px;
                width: 12px;
            }
        }
    }
}
