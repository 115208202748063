//
// List group emphasized
//

.list-group-emphasized {
    .list-group-item {
        transform: scale(1);
        @include transition($transition-base);

        &.active {
            z-index: 11;
            transform: scale(1.05);
            background-color: $navbar-dropdown-link-hover-bg;
            @include box-shadow($box-shadow);
            @include border-radius($navbar-dropdown-border-radius);

            .media {
                a {
                    color: theme-color("primary");
                }
            }
        }
    }
}
