//
// Actions
//


.action-item {
    display: inline-flex;
    align-items: center;
    font-size: $font-size-sm;
    cursor: pointer;
    color: $text-muted;
    background: transparent;
    border: 0;
    padding: .25rem .25rem;
    @include transition(color .3s);

    &:hover {
        color: lighten($text-muted, 10%);
    }

    svg {
        position: relative;
        bottom: .125rem;
    }

    span {
        padding-left: .35rem;
        display: inline-block;
    }
}

.actions-dark {
    .action-item {
        color: rgba($white, .7);

        &:hover {
            color: rgba($white, 1);
        }
    }
}

// Action item colors

.action-favorite {
    color: $favorite-color;

    &:hover,
    &.active {
        color: darken($favorite-color, 7%);
    }
}

.action-love {
    color: $love-color;

    &:hover,
    &.active {
        color: darken($love-color, 7%);
    }
}

.action-like {
    color: $like-color;

    &:hover,
    &.active {
        color: darken($like-color, 7%);
    }
}

// Sizing

.action-item-lg {
    font-size: $font-size-lg;
}

// Actions inside a card

.card {
    > .actions {
        position: absolute;
        right: .875rem;
        top: 1rem;
    }
}

.card-stats {
    .actions {
        right: .75rem;
        top: .75rem;
    }
}

// Toolbar

.actions-toolbar {
    position: relative;
}

// Search

.actions-search {
    display: none;
    opacity: 0;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: $card-bg;
    border-color: $card-border-color;
    z-index: 1;
    @include box-shadow($card-shadow);
    @include border-radius($card-border-radius);
    @include transition($transition-base);

    &.show {
        display: flex;
        opacity: 1;
    }
}
